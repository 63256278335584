@charset "UTF-8";

#global-message {
	text-align: center;
	color: var(--global-message-color);
	font-weight: bold;
	font-size: 13px;
	padding: 7px;
	background: var(--global-message-background);
}
#production-message {
	display: none;
	text-align: center;
	color: #fff;
	font-weight: bold;
	font-size: 13px;
	padding: 7px;
	background-color: if($app-euro, #50565b, #16f060);
	//animation: blinker 1s linear infinite;
	.show-production-message & {
		display: block;
		&:before{
			content: var(--environment-message);
		}
	}
}
@keyframes blinker {
	50% {
		opacity: 0;
	}
}
#header {
	background: var(--header-background);
	position: sticky;
	top:0;
	z-index: 2;
	@media print {
		display: none;
	}
	min-width: 800px;
	.full-screen-editor &{
		display:none !important;
	}
}
#top {
	height: 51px;
	position: relative;
}

#logo {
	position: absolute;
	top: 3px;
	left: 20px;
	height: 45px;
	@if ($app-euro) {
		width: 200px;
	} @else {
		width: 85px;
	}
	background: url($img-url + 'logo.svg') no-repeat left center;
	background-size: contain;
	&::after{
		top:20px;
		left:100%;
		color:var(--header-color);
		font-weight: bold;
		position: absolute;
		font-size:13px;
		margin-left:2px;
		text-transform: uppercase;
		content: var(--qa-number);
	}
}
#user-info {
	position: absolute;
	top: 20px;
	right: 10px;
	color: var(--header-color);
	font-weight: bold;
	.logout {
		margin-left: 10px;
		background: var(--akcent-background);
		color: var(--akcent-color);
		padding: 5px 10px;
		border-radius: 3px;
		border: var(--akcent-border);
		&:hover {
			filter: brightness(1.1);
		}
	}
}
