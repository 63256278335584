@charset "UTF-8";

.top-navigation {
	margin-top: 10px;
	overflow: hidden;
	.navigation-info {
		float: left;
		margin-top: 6px;
		width: 50%;
	}
	.pagination {
		margin: 5px 0;
		float: left;
		width: 50%;
		clear: both;
	}
	.navigation-items-on-page {
		float: right;
		width: 50%;
		text-align: right;
		select {
			min-width: 65px;
		}
	}
	.navigation-go-to-page {
		float: right;
		width: 50%;
		margin-top: 4px;
		text-align: right;
		input {
			width: 65px;
		}
	}
}
.bottom-navigation {
	padding: 10px 0 0;
	.pagination {
	}
}
.pagination {
	ul {
		padding: 0;
		margin: 0;
	}
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-block;
		&.active {
			a {
				background: var(--pagination-active-background);
				color: var(--pagination-active-color);
				border: var(--pagination-active-border);
			}
		}
	}
	a {
		display: inline-block;
		font-size: 10px;
		min-width: 24px;
		padding: 4px 2px;
		text-align: center;
		border-radius: 3px;
		background: var(--pagination-background);
		color:var(--pagination-color);
		border: var(--pagination-border);
	}
}
