@charset "UTF-8";

#forgot-password {
	background: var(--login-background);
	padding-top: 200px;
	#login {
		padding-top: 0;
	}
}

.forgot-password-box {
	background: #fff;
	width: 400px;
	padding: 20px 50px;
	border: 1px solid #ddd;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin: 0 auto;

	.forgot-password-header {
		padding: 10px 0;
		text-align: center;
		font-size: 14px;
	}

	.forgot-password-row {
		padding: 7px 30px;
	}

	input[type='password'],
	input[type='text'] {
		width: 100%;
	}

	.forgot-password-button {
		text-align: center;
	}

	input[type='submit'] {
		margin-top: 5px;
	}

	#forgot-password-captcha {
		margin-top: 20px;
		.info {
			margin-bottom: 5px;
		}
	}
	.error {
		font-weight: normal;
	}
}