@charset "UTF-8";

#alert, #confirm{
	max-width:350px;
	text-align:center;
	p{
		font-size:13px;
		font-weight:bold;
		margin:0 15px 5px;
	}
	button:not(.remodal-close){
		margin:10px auto 0;
	}
	.button-ok{
		width:100px;
	}
}
