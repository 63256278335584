@charset "UTF-8";

label.error {
	color: var(--error-color);
	font-weight: bold;
}
%disabled {
	background: #eee;
}
input[type='text'],
input[type='password'],
input[type='date'],
input[type='number'] {
	//input[type="color"],
	font-size: 12px;
	min-height: 26px;
	border-radius: 3px;
	border: 1px solid #a0a0a0;
	padding: 0 3px;
	background: #fff;
	display: inline-block;
	&:focus {
		outline: none !important;
		box-shadow: 0;
	}
	&.error {
		border-color: var(--error-border-color);
	}
	&:disabled {
		@extend %disabled;
	}
}
select {
	font-size: 12px;
	min-height: 26px;
	border-radius: 3px;
	border: 1px solid #a0a0a0;
	background: #f7f7f7;
	padding: 3px 3px;
	cursor: pointer;
	&:focus {
		outline: none !important;
		box-shadow: 0;
	}
	option {
		padding: 1px 0;
	}
	&.error {
		border-color: var(--error-border-color);
	}
	&:disabled {
		@extend %disabled;
	}
}
textarea {
	font-size: 11px;
	resize: vertical;
	min-height: 50px;
	border-radius: 3px;
	border: 1px solid #a0a0a0;
	outline: none !important;
	padding: 5px;
	&:focus {
		outline: none !important;
		box-shadow: 0;
	}
	&.error {
		border-color: var(--error-border-color);
	}
}
button {
	font-size: 12px;
	padding: 0 5px;
	min-height: 26px;
	border-radius: 3px;
	border: 1px solid #a0a0a0;
	background: #eee;
	cursor: pointer;
	&:focus {
		outline: none !important;
		box-shadow: 0;
	}
	&:hover {
		background: lighten(#eee, 1%);
	}
}
.datelocal-wrapper {
	display: flex;

	.datelocal-date,
	.datelocal-time {
		display: none;
		width: 150px !important;
		height: 26px;
	}
}
.firefox-mode {
	.datelocal-input {
		display: none;
	}
	.datelocal-date,
	.datelocal-time {
		display: block;
	}
}
.checkgroup {
	max-height: 420px;
	overflow-y: auto;
}
