.loading:not(img) {
	position: relative;
	z-index: 3;
	min-height: 70px;
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -23px;
		margin-left: -23px;
		height: 28px;
		width: 28px;
		z-index: 11;
		animation: rotate360 0.8s infinite linear;
		border: 8px solid #0975fd;
		border-right-color: transparent;
		border-radius: 50%;
		@keyframes rotate360 {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
		height: 100%;
		background: #fff;
		opacity: 0.6;
	}
}

.show-loading {
	cursor: wait;
	overflow: hidden;
	body {
		@extend .loading;
		&::after {
			position: fixed !important;
		}
	}
}
