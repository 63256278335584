#password-obligatory-change, #password-change{
	padding-top: 200px;
	background: var(--login-background);
	#login {
		padding-top: 0;
	}
}
.password-change-box {
	.change-password{
		position:absolute;
		top:7px;
		right: -1px;
		background: none;
		border:0;
		&:after{
			width:24px;
			height:24px;
			background: url("data:image/svg+xml;charset=utf8,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eremove_red_eye%3C/title%3E%3Cpath d='M16 12q1.625 0 2.813 1.188t1.188 2.813-1.188 2.813-2.813 1.188-2.813-1.188-1.188-2.813 1.188-2.813 2.813-1.188zM16 22.688q2.75 0 4.719-1.969t1.969-4.719-1.969-4.719-4.719-1.969-4.719 1.969-1.969 4.719 1.969 4.719 4.719 1.969zM16 6q4.938 0 8.938 2.75t5.75 7.25q-1.75 4.5-5.75 7.25t-8.938 2.75-8.938-2.75-5.75-7.25q1.75-4.5 5.75-7.25t8.938-2.75z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center;
			background-size: contain;
			content:'';
			display: block;
		}
		&.changed{
			&:after{
				background-image: url("data:image/svg+xml;charset=utf8,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Evisibility_off%3C/title%3E%3Cpath d='M15.813 12h0.188q1.625 0 2.813 1.188t1.188 2.813v0.25zM10.063 13.063q-0.75 1.5-0.75 2.938 0 2.75 1.969 4.719t4.719 1.969q1.438 0 2.938-0.75l-2.063-2.063q-0.5 0.125-0.875 0.125-1.625 0-2.813-1.188t-1.188-2.813q0-0.375 0.125-0.875zM2.688 5.688l1.688-1.688 23.625 23.625-1.688 1.688q-0.313-0.313-1.969-1.938t-2.531-2.5q-2.688 1.125-5.813 1.125-4.938 0-8.938-2.75t-5.75-7.25q0.688-1.625 2.156-3.594t2.844-3.031q-0.75-0.75-2.094-2.125t-1.531-1.563zM16 9.313q-1.25 0-2.438 0.5l-2.875-2.875q2.438-0.938 5.313-0.938 4.938 0 8.906 2.75t5.719 7.25q-1.5 3.688-4.563 6.313l-3.875-3.875q0.5-1.188 0.5-2.438 0-2.75-1.969-4.719t-4.719-1.969z'%3E%3C/path%3E%3C/svg%3E");
			}
		}
	}
	background: #fff;
	width: 400px;
	padding: 20px 50px;
	border: 1px solid #ddd;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
	margin: 0 auto;

	.password-change-header {
		padding: 10px 0;
		text-align: center;
		font-size: 14px;
	}

	.password-change-row {
		padding: 7px 30px;
		position: relative;
	}

	input[type=password],
	input[type=text] {
		width: 100%;
	}

	.password-change-button {
		text-align: center;
	}

	input[type="submit"] {
		margin-top: 5px;
	}

	#password-change-captcha {
		margin-top: 20px;
		.info {
			margin-bottom: 5px;
		}
	}
	.error {
		font-weight: normal;
	}
}
