.validation-error:not(:empty) {
	color: var(--error-color);
	font-weight: bold;
	display: inline-block;
}

.tabs-view {
	margin-top: 20px;
	width: 100%;
	.tabs-menu {
		margin: 0;
		padding: 0;
		overflow: hidden;
		li {
			list-style: none;
			cursor: pointer;
			display: block;
			float: left;
			padding: 7px 15px;
			font-size: 11px;
			line-height: 20px;
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
			background: #f0f0f0;
			font-weight: bold;
			&.active {
				background: #e7eddf;
			}
			&:last-child {
				border-right: 1px solid #ccc;
			}
		}
	}
	.tabs-list {
	}
	.tab {
		display: none;
		border: 1px solid #ccc;
		background-color: #fff;
		padding: 20px 10px;
		overflow: hidden;
		&.active {
			display: block;
		}
		&.loading {
			height: 300px;
			* {
				display: none;
			}
		}
	}

	.erp-order {
		margin-bottom: 8px;
		width: 300px;
		display: flex;
		justify-content: space-between;
		line-height: 2;
	}

	.erp-order-box {
		max-width: 620px;
		display: flex;
		justify-content: space-between;
		line-height: 2;
	}

	#search-erp-order-error {
		display: block;
		margin-bottom: 4px;
	}

	#orders-quantity {
		display: none;
		&.active {
			display: block;
		}
	}
}

#tabs-edit-coflift {
	width: 500px;
	text-align: center;
	.buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}
}
