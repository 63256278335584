@charset "UTF-8";


#batch-photo-preview {
	&:empty {
		display:none;
	}

	display:flex;
	flex-wrap:wrap;
	margin-top:10px;

	.batch-photo {
		width: 215px;
		height: 245px;
		vertical-align: top;
		padding: 5px;
		margin: 5px 5px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 1px solid #666;

		&.photo-loaded {
			.add-photo {
				display:none;
			}
		}

		img {
			display:block;
			margin-bottom: 5px;
			max-width: 203px;
			max-height: 141px;
		}

		.controls {
			label {
				margin-left: 20px;
			}
			.buttons {
				margin-bottom: 4px;
				display: flex;
				justify-content: space-between;
				button {
					padding: 0 15px;
				}
			}
		}

		&+.photo-loaded .move-left {
			visibility: visible;
		}
		&:first-child .move-left {
			visibility: hidden;
		}
		&:nth-last-child(2) .move-right, &:last-child .move-right{
			visibility: hidden;
		}
	}
	.delete-photo {
		width: 50px;
		align-self: flex-end;
	}

	&[data-photos="10"] {
		.photo-loaded:nth-child(9) .move-right {
			visibility: visible!important;
		}
	}
}
.wrong-codes, .code-errors, .validation-errors {
	color: var(--error-color);
	margin-top: 10px
	
}

.success-codes {
	color: green;
	margin-top: 10px
}
