@charset "UTF-8";

#filters {
	.filters-rows {
		display: table;
		margin: 0 auto;
	}
	.filter-row {
		display: table-row;
	}
	.filter-row-label {
		display: table-cell;
		text-align: right;
		padding-right: 5px;
		padding-top: 1px;
		.tooltip{
			vertical-align: middle;
			display: inline-block;
			margin-left: 2px;
			&:before{
				display: inline-block;
				width:16px;
				height:16px;
				content:"";
				background: url(/images/konsola/information.png);
			}
		}
	}
	.filter-row-field {
		display: table-cell;
		padding-top: 1px;
	}
	.filter-row-input {
		input {
			width: 200px;
		}
	}
	.filter-row-select {
		select {
			min-width: 200px;
		}
	}

	.filters-actions {
		padding-top: 10px;
		text-align: center;
		button {
			min-width: 100px;
		}
	}
}
