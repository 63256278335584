@charset "UTF-8";

/* for vendor/codemirror styles overwrite */
@media print {
	.CodeMirror-scroll {
		overflow: hidden !important;
		margin-bottom: 0px;
		padding-bottom: 10px;
	}
}
