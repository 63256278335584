@charset "UTF-8";

body {
	font-size: 12px;
	font-family: var(--font-family);
	color: var(--text-color);
	background: var(--page-background);
	margin: 0;
	padding: 0;
	min-height: 100vh !important;
	line-height: 1.3em;
	@media print {
		background: none !important;
		&::after {
			display: none;
		}
	}
}
html:not(.show-loading) body::after {
	position: fixed;
	font-size: 40px;
	bottom: 16px;
	right: 10px;
	text-transform: uppercase;
	content: var(--qa-number);
	opacity: 0.1;
}

*:not(iframe) {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	outline: none;
	color: var(--link-color);
	&:hover {
		text-decoration: none;
	}
}
