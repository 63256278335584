@charset "UTF-8";

html:not(.show-loading).fck-loading {
	body::after {
		position: fixed;
		top: 50%;
		bottom: auto;
		right: auto;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		content: "Czekamy na załadowanie wszystkich bloczków i gotowość do pracy";
		padding: 20px 20px 20px 40px;
		border-radius: 4px;
		text-transform: none;
		font-size: 14px;
		z-index: 10;
		opacity: 1;
		background: #fff url('/images/konsola/loader.gif') no-repeat 10px center;
	}

	body::before {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.4;
		background: #333;
		z-index: 9;
	}
}