@charset "UTF-8";

#module-menu {
	text-align: left;
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	@media print {
		display: none;
	}
	padding-left: 30px;
	position: relative;
	z-index: 2;
	height: 30px;
	display: flex;
	align-items: center;
	ul ul {
		display: none;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
		vertical-align: middle;
		position: relative;
	}
	.full-screen-editor &{
		display: none;
	}
}
#menu {
	display: flex;
	> li {
		> a {
			padding: 0 13px;
			display: inline-block;
			position: relative;
			@if ($app-euro) {
				color: #fff;
			} @else {
				color: #0a0a0a;
			}
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;
			line-height: 30px;
			font-size: 13px;
			font-weight: bold;
			height: 30px;
			white-space: nowrap;
		}
		&:not(:last-child):after {
			content: '';

			height: 12px;
			position: absolute;
			top: 6px;
			right: 0;
			display: block;
			@if ($app-euro) {
				border-right: 1px solid #555151;
			} @else {
				border-right: 1px solid #abe197;
			}
		}
		&:hover {
			> a {
				@if ($app-euro) {
					background: #dfdfdf;
					color: #333333;
				} @else {
					background: #31ec71;
				}
			}
		}
		&:hover,
		&.show {
			&:after {
				display: none !important;
			}
		}
	}
	&.active > li.show {
		> a {
			background: #fafafa;
			color: #1c1c1c;
			&:after {
				display: none;
			}
		}

		> ul {
			display: block;
			position: absolute;
			top: 30px;
			left: -1px;
		}
	}
	ul {
		border: 1px solid #cccccc;
		border-top: 0;
		width: 250px;
		background: #fafafa;
		&.wide {
			width: 350px;
			overflow: auto !important;
		}
		li {
			display: block;
			position: relative;

			ul {
				max-height: 70vh;
				border-top: 1px solid #cccccc;
				//overflow-y: auto;
				//width:350px;
			}
			&:hover > a,
			&.visible > a {
				background: #ececec;
				//border: 1px solid #6D9D2E;
			}
			&.visible {
				> ul {
					display: block;
					position: absolute;
					top: 0;
					left: 248px;
					overflow-y: initial;
					max-height: 60vh;
					ul{
						top: -1px;
					}
				}
			}
			&:nth-child(n + 15) > ul {
				top: auto;
				bottom: -1px;
			}
			> *:nth-last-child(2) {
				&:after {
					position: absolute;
					right: 5px;
					top: 8px;
					content: '';
					width: 0px;
					height: 0px;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-left: 5px solid #acacac;
				}
			}
		}
		a {
			font-size: 11px;
			color: #1c1c1c;
			font-weight: normal;
			line-height: 1.2em;
			padding: 5px 12px 5px 10px;
			cursor: pointer;
			display: block;
			border: 1px solid transparent;
			white-space: pre-wrap;
		}
	}
}
