@charset "UTF-8";

.edit-table {
	display: table;
	width: 100%;
	margin: auto;
	border-left: 1px solid var(--border-color);
	border-top: 1px solid var(--border-color);
	color: var(--text-color);
	@media print {
		border: 0;
	}
}
.table-separator {
	background: #a5dea9;
	padding: 5px;
	text-align: center;
	border-left: 1px solid var(--border-color);
	border-right: 1px solid var(--border-color);
	label {
		font-weight: bold;
		color: var(--text-color);
	}
}
