@charset "UTF-8";

.button-action,
.button-row-action,
.button-download {
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
	+ .button-action {
		margin-left: 10px;
	}
	&:before {
		content: '';
		background-position: center center;
		background-repeat: no-repeat;
		width: 19px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
	}
	&[hidden] {
		display: none !important;
	}
	&[data-disabled='true'] {
		opacity: 0.5;
		&[title] {
			cursor: help;
		}
	}

	&.button-disabled {
		color: #81818e;
		pointer-events: none;
	}
}

.button-add:before {
	background-image: url(/images/konsola/actions/add.gif);
}
.button-new:before {
	background-image: url(/images/konsola/actions/add.gif);
}
.button-refresh:before {
	background-image: url(/images/konsola/actions/publish.gif);
}
.button-edit:before {
	background-image: url(/images/konsola/actions/edit.gif);
}
.button-history:before {
	background-image: url(/images/konsola/taskView.gif);
}
.button-raport:before {
	background-image: url(/images/konsola/taskEdit.gif);
}
.button-delete:before {
	background-image: url(/images/konsola/close.gif);
}
.button-unpublish:before {
	background-image: url(/images/konsola/actions/unpublish.gif);
}
.button-publish:before {
	background-image: url(/images/konsola/actions/publish.gif);
}
.button-back:before {
	background-image: url(/images/konsola/taskBack.gif);
}
.button-preview:before {
	background-image: url(/images/konsola/actions/preview.gif);
}
.button-copy:before {
	background-image: url(/images/konsola/taskCopy.gif);
}
.button-sort:before {
	background-image: url(/images/konsola/appdata_scheme.gif);
}
.button-save:before {
	background-image: url(/images/konsola/taskSave.gif);
}
.button-save_back:before {
	background-image: url(/images/konsola/taskSaveBack.gif);
}
.button-download:before {
	background-image: url(/images/konsola/actions/archivize.gif);
}
.button-archive:before {
	background-image: url(/images/konsola/actions/archivize.gif);
}
.button-report:before {
	background-image: url(/images/konsola/taskNotes.gif);
}
.button-restore:before {
	background-image: url(/images/konsola/actions/unpublish.gif);
}
.button-report:before {
	background-image: url(/images/konsola/icon/report.png);
}
.button-cancel:before {
	background-image: url(/images/konsola/actions/unpublish.gif);
}
.button-open:before {
	background-image: url(/images/konsola/actions/view_subelements.gif);
}
.button-next:before {
	background-image: url(/images/konsola/actions/execute.gif);
}
.button-user:before {
	background-image: url(/images/konsola/join.gif);
}
.button-move_up:before {
	content: '^' !important;
	width: auto;
	height: auto;
}
.button-move_down:before {
	content: 'v' !important;
	width: auto;
	height: auto;
}
.button-add:before {
	background-image: url(/images/konsola/taskAdd.gif);
}
