@charset "UTF-8";
@import 'app';

$img-url: '/img/admin/euro/' !default;

@if ($app == 'ole') {
	$img-url: '/img/admin/ole/';
}

:root{
	--font-family: 'Lato', 'Verdana', 'Helvetica CE', 'Helvetica', 'Arial CE', 'Arial';
	--th-background: #3a3d40;
	--border-color: #3a3d40;
	--link-color: #222;
	--text-color: #222;
	--error-color: red;
	--error-border-color: red;
	--error-background: #ffcccc;
	--pagination-background: #e1e1e1;
	--pagination-color: #3a3d40;
	--pagination-border: 1px solid #3a3d40;
	--pagination-active-background: #3a3d40;
	--pagination-active-color: #fff;
	--pagination-active-border: 1px solid #3a3d40;
	--global-message-color: #fff;
	--global-message-background: red;
	--akcent-background: #f4f556;
	--akcent-color: #222;
	--akcent-border: none;
	--header-background:  #3a3d40;
	--header-color: #fff;
	--login-background: #3a3d40;
	--page-background: #fff;

	@if($app-ole){
		--akcent-background: #00dc4b;
		--header-background: #00dc4b;
		--header-color: #0a0a0a;
		--login-background: #00dc4b;
	}
}