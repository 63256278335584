@charset "UTF-8";

#password-recovery {
	background: var(--login-background);
	.password-recovery-box {
		background: #fff;
		width: 400px;
		padding: 20px 50px;
		border: 1px solid #ddd;
		border-radius: 3px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		margin: 0 auto;
	}
	.password-recovery-message {
		padding: 10px 0;
		text-align: center;
		font-size: 14px;
		color: var(--error-color);
	}
	.password-recovery-row {
		padding: 7px 30px;
	}
	.password-recovery-form {
		width: 500px;
		margin: 0 auto;
	}
	.password-recovery-form-header {
		margin: 60px auto 30px;
		font-size: 14px;
		text-align: center;
	}
	.password-recovery-input {
		width: 100%;
		font-size: 12px;
	}
	.password-recovery-form-submit {
		text-align: center;
	}
	.password-recovery-success-box {
		text-align: center;
	}
}
