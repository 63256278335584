@charset "UTF-8";

#welcome {
	h1 {
		text-align: center;
		font-size: 24px;
		margin: 0;
		padding: 50px 0;
	}
}
