@charset "UTF-8";

.group {
	display: table-row;
	position: relative;
	@media print {
		display: block;
		border: 0;
		clear: both;
		page-break-after: always;
		font-size: 10px;
	}
	> * {
		border-right: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		display: table-cell;
		vertical-align: middle;
		@media print {
			display: block;
			border: 0;
		}
	}
	select:not([multiple]) {
		height: 26px;
	}
	&.group-hidden {
		display: none;
	}

	&.group-print-hidden {
		@media print {
			display: none;
		}
	}

	> div {
		padding: 1px 3px;
		@media print {
			border: 0;
		}
	}
	> label {
		padding: 5px;
		font-weight: bold;
		font-size: 12px;
		text-align: left;
		width: 200px;
		@media print {
			padding: 20px 5px 10px;
			width: 100%;
			border-bottom: 1px solid #0e335a;
		}
		.count-text {
			font-weight: normal;
			font-size: 10px;
			float: right;
			&:before {
				content: '(' attr(data-count) ' z ' attr(data-max) ')';
			}
			&.max {
				color: var(--error-color);
				font-weight: bold;
			}
		}
	}
	&.group-text > div {
		padding: 5px;
		.text-content,
		.html-content,
		.body-content {
			max-height: 100px;
			overflow-y: auto;
			@media print {
				overflow: visible;
			}
		}
		.empty-content {
			&:before {
				content: 'puste';
				opacity: 0.3;
			}
		}
	}
	&.group-empty > div {
		.empty-content {
			&:before {
				content: 'puste';
				opacity: 0.3;
			}
		}
	}
	&.group-input input[type='text'] {
		width: 100%;
	}
	&.group-date-hour-minute input[type='date'] {
		min-width: 125px;
	}
	&.group-input-color {
		.color {
			width: 90px;
			height: 26px;
			display: inline-block;
			vertical-align: bottom;
		}
	}
	&.group-redactor {
		> div {
			padding: 0;
		}
		.redactor-box.redactor-styles-on {
			border: none;
			border-radius: 0;
		}
		.open-fck {
			margin: 5px 3px;
		}
	}
	&.group-codeMirror textarea {
		width: 100%;
	}
	&.group-textarea textarea {
		width: 100%;
	}
	&:nth-child(2n + 1) {
		background-color: #e1e1e1;
		@media print {
			background-color: transparent;
		}
	}
	&.group-checkboxes {
		.checkboxes {
			overflow-y: auto;
			li {
				list-style: none;
				overflow: hidden;
				margin: 0;
				padding: 0;
			}
			ul {
				margin: 0;
				padding: 0;
			}
		}
	}
	&.group-radios {
		.radios {
			overflow-y: auto;
			li {
				list-style: none;
				margin: 0;
				padding: 0;
			}
			ul {
				margin: 0;
				padding: 0;
			}
		}
	}
	&.group-list-row {
		> div {
		}
		.list-box{
			display: table;
			width: 100%;
			border-collapse: collapse;
			& > div {
				display: table-row;
				& > div {
					display: table-cell;

					border: 1px solid #0e335a;
				}
			}
		}
		.list-element.hidden{
			display: none !important;
		}
		.list-element,
		.list-new {
			& > div {
				padding: 1px;
			}
			input {
				width: 100%;
			}
		}
		.list-head {
			> div {
				background: #0e335a;
				font-weight: bold;
				text-align: center;
				color: #fff;
				padding: 5px;
			}
		}
		input:disabled {
			background: #ccc !important;
			cursor: not-allowed;
		}
	}
	.note {
		margin: 5px 0;
		color: #333;
		font-size: 11px;
		&:empty {
			display: none;
		}
	}
	label .info {
		position: relative;
		display: inline-block;
		&:before {
			margin-left: 3px;
			content: '?';
			display: inline-block;
			width: 12px;
			height: 12px;
			font-size: 11px;
			background: #fff;
			border: 1px solid #ddd;
			text-align: center;
			font-weight: bold;
			border-radius: 50%;
		}
		div {
			display: none;
			margin: 3px;
			position: absolute;
			bottom: 13px;
			left: 13px;
			min-width: 200px;
			max-width: 300px;
			padding: 10px;
			border: 1px solid #ddd;
			border-radius: 2px;
			background: #f4f4f4;
			font-weight: normal;
		}
		&:hover {
			cursor: helps;
			div {
				display: block;
			}
		}
	}
}
.row-date-hour-minute {
	label.label {
		display: inline-block;
		margin-right: 3px;
		+ input {
			margin-right: 6px;
		}
	}
	select {
		margin: 0 2px;
	}
	display: flex;
	line-height: 26px;
	+ .row-date-hour-minute {
		margin-top: 4px;
	}
}

.group-redactor {
	.redactor-component[data-redactor-type='image'] {
		img[src='//f00.esfr.pl/images/www/blank.gif'], img[src='/images/www/blank.gif']
		{
			max-height: 100px !important;
			background: #eee !important;
		}
	}
}
