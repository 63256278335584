.filters-table-group {
	.subfilter-options {
		display: none;
	}
	.subselect-options-variant select {
		min-width: 100px;
	}
	.filter-selection-first-column {
		width: 25%;
		vertical-align: top;
	}
	.filter-selection-actions-column {
		width: 100px;
	}
	.hide-element {
		display: none;
	}
}