@charset "UTF-8";

.history {
	color: var(--text-color);
	header {
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
	}
}
