@charset "UTF-8";

#modal-page{
	width:80%;
	max-width: 1000px;
	#page-actions{
		text-align: left;
	}
}
#ajax-box{
	margin-top:10px;
	&.error{
		border:1px solid var(--error-border-color);
		text-align:center;
		color:var(--error-color);
		background:#fff;
		padding:20px;
		font-weight: bold;
	}
}
