@charset "UTF-8";

.preview-table {
	display: table;
	width: 100%;
	margin: auto;
	border-left: 1px solid #0e335a;
	border-top: 1px solid #0e335a;
	color: #0e335a;
	+ .preview-table {
		margin-top: 20px;
	}
}
