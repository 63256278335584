@charset "UTF-8";

.sticky {
	#top{
		display: none;
	}
}
body:not(.sticky) #sticky-menu {
	display: none;
}
#sticky-menu {
	padding: 2px 20px 0 0!important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	a{
		@if($app-euro){
			color:#fff;
		}@else{
			color: #1c1c1c;
		}
	}
}
