@charset "UTF-8";

#modal-audit{
	border:1px solid #eee;
	box-shadow: 0 1px 2px rgba(0,0,0,.2);
	.row{
		padding:5px;
		display: flex;
		label{
			width:40%;
			text-align:right;
			padding:4px 10px 0 0;
			font-weight: bold;
		}
		> div{
			width:60%;
			position: relative;
			.datalist{
				&:empty{
					display: none;
				}
				&.has-datalist:empty + .datalist-status{
					position: absolute;
					top: 16px;
					right: 12px;
					&:before{
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 6px 4px 0 4px;
						border-color: var(--text-color) transparent transparent transparent;
					}
				}
				position: absolute;
				top:26px;
				left:0;
				border:1px solid #eee;
				background:#fff;
				width: 100%;
				max-height: 252px;
				overflow-y: auto;
				> div {
					cursor: pointer;
					&:nth-child(odd){
						background:#f9f9f9;
					}
					line-height: 15px;
					&:hover{
						background:#eee;
					}
					padding: 5px;
				}
			}
		}
		#audit-text{
			width:100%;
		}
		button{
			display: inline-block;
		}
	}
	#audit-box.error {
		& > div:after{
			content:"Uzupełnij komentarz";
			display: block;
			padding:5px;
			color:var(--error-color);
			font-weight: bold;
			text-align: center;
		}
		#audit-text{
			border-color: var(--error-border-color);
		}
	}
	.buttons{
		text-align: center;
		display: block;

		button{
			margin:5px;
		}
	}

}
