.remodal-overlay {
	z-index: 5;
	background: rgba(245, 245, 245, 0.8);
}

.remodal-wrapper {
	z-index: 6;
}
.remodal {
	text-align: initial;
}

#modal-exception {
	max-height: 80vh;
	max-width: 80%;
	overflow-y: auto;
}
#modal-add-ajax-photo {
	width: 300px;
	h2 {
		display: block;
		margin: 0;
		padding: 10px 0 20px;
	}
	input {
		width: 100%;
	}
	div button {
		margin-top: 10px;
		width: 100%;
	}
}
