@charset "UTF-8";

#login {
	padding-top: 200px;
	background: var(--login-background);
}
#login-box {
	background: #fff;
	width: 400px;
	padding: 20px 50px;
	border: 1px solid #ddd;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin: 0 auto;

	.login-header {
		padding: 10px 0;
		text-align: center;
		font-size: 14px;
	}

	.login-row {
		padding: 7px 30px;
	}

	input[type='password'],
	input[type='text'] {
		width: 100%;
	}

	.login-button {
		text-align: center;
	}

	button {
		background: var(--akcent-background);
		color: var(--akcent-color);
		border: var(--akcent-border);
		font-weight: bold;
		padding: 7px 30px;
		text-transform: uppercase;
		margin-top: 5px;
	}
	.login-error {
		color: var(--error-color);
		background: var(--error-background);
	}
	.error {
		font-weight: normal;
	}
}
