@charset "UTF-8";

.ac_results.search-view {
	li {
		text-align: left;
	}
	small {
		display: block;
		font-size: 9px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #777;
	}
	.ac_over small {
		color: #fff;
	}
}
#search-views {
	margin-left: auto;
	position: relative;
	input {
		height: 22px;
		min-height: 22px;
		display: block;
		margin: 0 10px;
		font-size: 11px;
		width: 150px;
		border-radius: 3px;
		padding: 0 10px;
		line-height: 22px;
		outline: 0;
		border: 0;
		transition: all 0.5s;
		&:focus {
			width: 350px;
		}
	}
}
