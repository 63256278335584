@charset "UTF-8";

table {
	border: 1px solid var(--border-color);
	width: 100%;
	border-collapse: collapse;
}
th {
	background-color: var(--th-background);
	color: #f0f0f0;
	font-weight: bold;
	font-size: 11px;
	text-align: center;
	padding: 1px 3px;
	&:not(:last-child) {
		border-right: 1px solid var(--border-color);
	}
	&.select {
		white-space: nowrap;
	}
	.asc,
	.desc {
		width: 15px;
		height: 15px;
		display: inline-block;
	}
	.order {
		white-space: nowrap;
		vertical-align: middle;
		display: inline-block;
		margin-left: 3px;
	}
	.asc {
		background: url(/images/konsola/za_false.gif) no-repeat center center;
	}
	.desc {
		background: url(/images/konsola/az_false.gif) no-repeat center center;
	}
	&.select-asc .asc,
	&.select-desc .desc {
		filter: invert(100%);
	}
}
td {
	border: 1px solid var(--border-color);
	padding: 1px 4px;
	&.move-buttons {
		text-align: center;
		white-space: nowrap;
	}
}
tr {
	&:nth-child(odd) {
		td {
			background-color: #eeeded;
		}
	}
	&:hover {
		td {
			background-color: #45caa0;
		}
	}
}
