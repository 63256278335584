@charset "UTF-8";

#error-403{
	h1{
		text-align: center;
		font-size:24px;
		margin:0;
		padding:50px 0;
	}
	p{
		text-align: center;
	}
}
