$app: "ole";
@charset "UTF-8";
@import 'app';
@import 'variables';
@import 'base';
@import 'forms';
@import 'buttons';
@import 'loading';
@import 'font';
@import 'top';
@import 'menu';
@import 'search-views';
@import 'modal';
@import 'fck';
@import 'filters';
@import 'filters-table-group';
@import 'table';
@import 'actions';
@import 'pagination';
@import 'alert';
@import 'audit';
@import 'group';
@import 'edit-table';
@import 'preview-table';
@import 'history';
@import 'ajax-page';
@import 'sticky';
@import 'codemirror';
@import 'captcha';

@import 'login-view';
@import 'forgot-password-view';
@import 'password-change-view';
@import 'password-recovery-view';
@import 'welcome-view';
@import 'error-403-view';
@import 'error-webflow-view';
@import 'batch-photos-view';
@import 'tabs-view';

#page-title {
	text-align: center;
	padding: 0 0 20px;
	h1 {
		font-size: 14px;
		margin: auto;
		padding: 0;
	}
}
#content {
	margin: 10px auto 20px;
	width: 90%;
	position: relative;
	z-index: 1;
}
.error-message {
	padding: 10px;
	background: var(--error-color);
	font-weight: bold;
	color: #fff;
	margin: 10px 0;
}
.text-center {
	text-align: center;
}
.nowrap {
	white-space: nowrap;
}
#list-form {
	color: var(--text-color);
}
.list-form-empty {
	text-align: center;
	padding: 30px;
	font-size: 12px;
	background: #eee;
	margin-top: 15px;
}

.page-error {
	background: var(--error-color);
	padding: 15px;
	color: #fff;
	font-weight: bold;
	font-size: 13px;
	text-align: center;
	margin-bottom: 10px;
}

.remodal-overlay {
	z-index: 8;
}
.remodal-wrapper {
	z-index: 9;
}
